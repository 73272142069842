<template>
  <div class="lad-container-media-text">
    <el-container v-if="withContainer">
      <el-main>
        <LadContainerMediaTextSection
          :title="title"
          :svg="svg"
          :asterisk="asterisk"
          :with-title="withTitle"
        >
          <slot v-if="withTitle" slot="title" name="title" />
          <slot slot="image" name="image" />
          <slot slot="description" name="description" />
        </LadContainerMediaTextSection>

        <el-divider v-if="withDivider" />
      </el-main>
    </el-container>

    <template v-else>
      <LadContainerMediaTextSection
        :title="title"
        :svg="svg"
        :asterisk="asterisk"
        :with-title="withTitle"
      >
        <slot v-if="withTitle" slot="title" name="title" />
        <slot slot="image" name="image" />
        <slot slot="description" name="description" />
      </LadContainerMediaTextSection>

      <el-divider v-if="withDivider" />
    </template>
  </div>
</template>

<script>
import LadContainerMediaTextSection from './component/section'

export default {
  name: 'LadContainerMediaText',
  components: { LadContainerMediaTextSection },
  props: {
    title: { type: String, default: '' },
    // eslint-disable-next-line vue/require-default-prop
    svg: { type: String, required: false },
    asterisk: { type: Boolean, default: false },
    withDivider: { type: Boolean, default: false },
    // eslint-disable-next-line vue/no-boolean-default
    withContainer: { type: Boolean, default: true },
    // eslint-disable-next-line vue/no-boolean-default
    withTitle: { type: Boolean, default: true },
  },
  computed: {
    hasSvg() {
      return !!this.svg
    },
  },
}
</script>

<style lang="scss">
.lad-container-media-text {
  padding: 40px 0;

  &--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &--left {
    flex-basis: 60%;
    text-align: left;
    padding: 0 20px;
  }
  &--right {
    flex-basis: 40%;
    width: 100%;

    .sprite-icons {
      // font-size: 12em;
      height: 170px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0;

    &--wrapper {
      flex-direction: column;
    }

    &--left {
      order: 2;
      text-align: center;
    }
    &--right {
      order: 1;

      .sprite-icons {
        // font-size: 8em;
        height: 115px;
      }
    }
  }
}
</style>
