<template>
  <div class="lad-container-media-text--wrapper">
    <div class="lad-container-media-text--left">
      <slot v-if="withTitle" name="title">
        <h2>{{ title }} <span v-if="asterisk">*</span></h2>
      </slot>
      <slot name="description" />
    </div>
    <div class="lad-container-media-text--right">
      <slot name="image">
        <svg-icon v-if="hasSvg" :name="svg" class="sprite-icons" />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LadContainerMediaTextSection',
  props: {
    title: { type: String, default: '' },
    // eslint-disable-next-line vue/require-default-prop
    svg: { type: String, required: false },
    asterisk: { type: Boolean, default: false },
    // eslint-disable-next-line vue/no-boolean-default
    withTitle: { type: Boolean, default: true },
  },
  computed: {
    hasSvg() {
      return !!this.svg
    },
  },
}
</script>
